import { graphql } from 'gatsby';
import React from 'react';
import ConversionPanel from '../components/ConversionPanel';
import GeneralHead from '../components/generalHead';
import Layout from '../components/layout';
import PressReleaseContent from '../components/NewsRoom/PressReleaseContent';
import SEO from '../components/seo';
import { conversion } from '../utils/aboutDummyData';

const PressRelease = ({ data: { pressRelease } }) => (
  <Layout forceShowHeader forceShowFooter>
    <PressReleaseContent pressRelease={pressRelease} />
    <ConversionPanel
      beforeFooter
      component={{
        ...conversion,
        topColor: { hex: 'var(--accent-color)' },
      }}
    />
  </Layout>
);

export const Head = ({
  data: {
    pressRelease: { seo },
  },
}) => (
  <GeneralHead>
    <SEO seo={seo} indexable />
  </GeneralHead>
);

export const query = graphql`
  query($id: String!) {
    pressRelease: datoCmsPressRelease(id: { eq: $id }) {
      id
      slug
      title
      excerpt
      category: newsroomCategory {
        name
      }
      author
      datePublished(formatString: "MMMM Do, YYYY")
      brandLogo {
        gatsbyImageData(
          imgixParams: {
            fm: "webp"
            auto: "compress"
            maxW: 1080
            fit: "clip"
            q: 35
          }
        )
        alt
        url
        fluid(imgixParams: { fm: "webp", auto: "compress" }) {
          src
          ...GatsbyDatoCmsFluid
        }
      }
      featuredImage {
        gatsbyImageData(
          imgixParams: {
            fm: "webp"
            auto: "compress"
            maxW: 1080
            fit: "clip"
            q: 35
          }
        )
        alt
        url
        fluid(imgixParams: { fm: "webp", auto: "compress", q: 35 }) {
          src
          ...GatsbyDatoCmsFluid
        }
      }
      imageCredit
      content {
        value
        links {
          __typename
          ... on DatoCmsComponentTestimonial {
            id: originalId
            body
            headline
            person {
              role
              internalName
              firstName
              lastName
              company {
                name
              }
            }
          }
          ... on DatoCmsWebsiteVideo {
            id: originalId
            internalName
            youtubeUrl
            videoThumbnail {
              ...datoCmsWebsiteImage
            }
          }
          ... on DatoCmsWebsiteImage {
            id: originalId
            internalName
            image {
              ...datoCmsFileField
            }
            backgroundImage {
              ...datoCmsFileField
            }
            imageMobile {
              ...datoCmsFileField
            }
          }
        }
      }
      sidebar {
        value
        links {
          __typename
          ... on DatoCmsComponentTestimonial {
            id: originalId
            body
            headline
            person {
              role
              internalName
              firstName
              lastName
              company {
                name
              }
            }
          }
        }
      }
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`;

export default PressRelease;
